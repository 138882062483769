import React from 'react';
import Illustration from '../../components/Illustration';
import ContactForm from '../../components/ContactForm';
import './HeroPage.css';

function HeroPage() {
  return (
    <div className="hero-page">
      <section className="hero-page__presentation">
        <div className="hero-page__info">
          <h1 className="hero-page__title">
            distributed browser process automation platform
          </h1>
          <p className="hero-page__description">
            A distributed web automation platform to help online businesses
            automate repetitive web browsing tasks.
          </p>
        </div>
        <div className="hero-page__illustration">
          <Illustration className="hero__page-svg" />
        </div>
      </section>
      <section className="hero-page__contact">
        <ContactForm />
      </section>
      <div className="scroll-indicator" />
    </div>
  );
}
export default HeroPage;
