import React from 'react';
import './Logo.css';

function Logo() {
  return (
    <figure className="logo__wrapper">
      <img className="logo__image" src="/crawless@2x.png" alt="Crawless Logo" />
    </figure>
  );
}

export default Logo;
