import React, { Component } from 'react';
import scrollSnapPolyfill from 'css-scroll-snap-polyfill';
import Header from './components/Header';
import HeroPage from './pages/Hero';
import FeaturesPage from './pages/Features';
import './App.css';

class App extends Component {
  componentDidMount() {
    scrollSnapPolyfill();
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        <main className="app__container">
          <HeroPage />
          <FeaturesPage />
        </main>
      </React.Fragment>
    );
  }
}

export default App;
