import React from 'react';
import './Icon.css';
import PaperPlane from './icons/PaperPlane';

/* eslint-disable react/require-render-return */
// eslint-disable-next-line react/prefer-stateless-function
class Icon extends React.Component {
  render() {
    throw new Error(
      'The `Svg` component is not meant to be rendered directly! ' +
        "It's an abstract component that is only valid with child components like: <Svg.Add />"
    );
  }
}

Icon.PaperPlane = PaperPlane;

export default Icon;
