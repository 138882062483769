import React, { useState } from 'react';
import Pageclip from 'pageclip';

import Icon from '../Icon';
import './ContactForm.css';

const pageclipAPIKey = 'api_5IOBIXKUhHWr1bw9PJf5CoJ0TsSOcCuW';
const pageclip = new Pageclip(pageclipAPIKey);

function ContactForm() {
  const [email, setEmail] = useState('');

  const handleEmailChange = e => {
    setEmail(e.target.value);
  };

  const submitForm = () => {
    pageclip
      .send({ email })
      .then(response => {
        console.log(response.status, response.data);
      })
      .then(() => {
        return pageclip.fetch();
      })
      .then(response => {
        console.log(response.status, response.data);
      });
  };

  return (
    <form
      autoComplete="off"
      onSubmit={submitForm}
      action="https://send.pageclip.co/5IOBIXKUhHWr1bw9PJf5CoJ0TsSOcCuW"
      method="POST"
      className="pageclip-form contact-form"
    >
      <input
        onChange={handleEmailChange}
        value={email}
        type="email"
        placeholder="Get Early Access"
        className="contact-form__input"
        name="email"
        required
      />
      <button type="submit" className="contact-form__button">
        <Icon.PaperPlane />
      </button>
    </form>
  );
}

export default ContactForm;
