import React from 'react';
import Logo from '../Logo';
import SocialLinks from '../SocialLinks';
import './Header.css';

function Header() {
  return (
    <header className="header">
      <Logo />
      <SocialLinks />
    </header>
  );
}

export default Header;
