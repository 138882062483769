import React from 'react';
import Tabs from '../../components/Tabs';

import './Features.css';

function FeaturesPage() {
  return (
    <div className="features-page">
      <Tabs>
        <div label="browser.">
          <section className="feature__section">
            <div className="feature__section-info">
              <h3 className="feature__title">headless chrome</h3>
              <p className="feature__description">
              Built on top of Google’s open source Web Browser
              it is designed to mimic human behaviour using 
              pre-programmed workflows that execute a specific business task.
              </p>
            </div>
            <img
              className="feature__section-image"
              src="/images/data.png"
              alt="Crawless Big Data"
            />
          </section>
        </div>
        <div label="process.">
          <section className="feature__section">
            <div className="feature__section-info">
              <h3 className="feature__title">wasted time</h3>
              <p className="feature__description">
              A considerable amount of time is wasted while performing manual,
              repetitive web browsing tasks increasing human error and decreasing productivity.
              </p>
            </div>
            <img
              className="feature__section-image"
              src="/images/structured.png"
              alt="Crawless Big Data"
            />
          </section>
        </div>
        <div label="automation">
          <section className="feature__section">
            <div className="feature__section-info">
              <h3 className="feature__title">edge computing</h3>
              <p className="feature__description">
              We run on Edge Computing which is the exact opposite to Cloud Computing. 
              In Edge, the computation is done at the “Edge” of the network, 
              which in our case is a personal computer.
              </p>
            </div>
            <img
              className="feature__section-image"
              src="/images/edge.png"
              alt="Crawless Big Data"
            />
          </section>
        </div>
      </Tabs>
    </div>
  );
}

export default FeaturesPage;
