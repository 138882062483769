import React from 'react';

export default function() {
  return (
    <svg
      className="svg-icon"
      fillRule="evenodd"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23 20"
    >
      <path d="M22.279.178a.751.751 0 0 0-.748-.131L.42 7.889C.164 7.985 0 8.207 0 8.454c.002.248.17.467.428.561l8.49 3.05 3.428 7.554c.105.23.352.38.634.381a.688.688 0 0 0 .631-.375L22.425.843a.559.559 0 0 0-.146-.665zm-7.594 7.625a.579.579 0 0 0 .202-.434.578.578 0 0 0-.202-.434.752.752 0 0 0-.975 0l-4.472 3.98-6.847-2.461 18.175-6.752-7.589 16.17-2.765-6.091 4.473-3.978z" />
    </svg>
  );
}
