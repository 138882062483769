import React from 'react';
import './SocialLinks.css';

function SocialLinks() {
  return (
    <ul className="social-links__list">
      <li className="social-links__list-item">
        <a
          href="https://fb.me/crawlessapp"
          target="_blank"
          rel="noopener noreferrer"
          className="social-link"
        >
          fb
        </a>
      </li>
      <li className="social-links__list-item">
        <a
          href="https://www.instagram.com/crawlessapp"
          target="_blank"
          rel="noopener noreferrer"
          className="social-link"
        >
          in
        </a>
      </li>
      <li className="social-links__list-item">
        <a
          href="https://www.linkedin.com/company/crawless/about"
          target="_blank"
          rel="noopener noreferrer"
          className="social-link"
        >
          ln
        </a>
      </li>
    </ul>
  );
}

export default SocialLinks;
